import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, IconButton, CircularProgress, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Get, Put } from '../../../../../Common/WebRequest';

const WeeklyHoursManager = () => {
    const [weeklyHours, setWeeklyHours] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        fetchWeeklyHours();
    }, []);

    const fetchWeeklyHours = async () => {
        setIsLoading(true);
        setMessage('');
        setSuccess(false);
        try {
            const response = await Get('Constants/weekly-hours');
            if (response.isSuccess) {
                setWeeklyHours(response.result);
                setSuccess(true);
                setMessage('Weekly hours fetched successfully!');
            } else {
                setSuccess(false);
                setMessage('Failed to fetch weekly hours.');
            }
        } catch (error) {
            setSuccess(false);
            setMessage('Failed to fetch weekly hours.');
        }
        setIsLoading(false);
    };

    const updateWeeklyHours = async () => {
        if (!weeklyHours || weeklyHours<=0 || weeklyHours>=1000) {
            setSuccess(false);
            setMessage('Please enter valid weekly hours.');
            return;
        }
        setIsUpdating(true);
        setMessage('');
        setSuccess(false);
        try {
            const response = await Put('Constants/update-weekly-hours', 0, { weeklyHours: parseInt(weeklyHours, 10) });
            if (response.isSuccess) {
                setSuccess(true);
                setMessage('Weekly hours updated successfully.');
            } else {
                setSuccess(false);
                setMessage('Failed to update weekly hours.');
            }
        } catch (error) {
            setSuccess(false);
            setMessage('Failed to update weekly hours.');
        }
        setIsUpdating(false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 4, ml: 4 }}>
            <TextField
                label="Weekly Hours"
                type="number"
                variant="outlined"
                value={weeklyHours}
                onChange={e => setWeeklyHours(e.target.value)}
                disabled={isLoading || isUpdating}
                sx={{ mb: 2, width: '250px' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={updateWeeklyHours}
                    disabled={isLoading || isUpdating}
                    color="primary"
                >
                    Update
                </Button>
                <IconButton onClick={fetchWeeklyHours} disabled={isLoading || isUpdating}>
                    {isLoading ? <CircularProgress size={24} /> : <RefreshIcon />}
                </IconButton>
            </Box>
            {message && (
                <Typography sx={{ mt: 2, color: success ? 'green' : 'red' }}>
                    {message}
                </Typography>
            )}
        </Box>
    );
};

export default WeeklyHoursManager;

import * as React from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { useEffect } from "react";

import { Get } from "../../../../Common/WebRequest";
import { blue, red } from "@mui/material/colors";

import columnsBuilderWeeklyHours from "./columnsBuilderWeeklyHours";

export default function ApprovedPartnersWeeklyHours() {
  const [approvedParentsData, setApprovedParentsData] = useState([]);

  const [reload, setReload] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      let res = await Get("Payment/user-weekly-hours");

      if (res.isSuccess) {
        setApprovedParentsData(res.result);
        setError(null);
      } else {
        if (res.Status !== 404) setError(res.Status + "/" + res.Error);
      }
    })();
  }, [reload]);

  function handleRefresh() {
    setApprovedParentsData([]);
    setReload(!reload);
  }

  function DefinedToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector>
            <GridToolbarDensitySelector />
          </GridToolbarDensitySelector>
          <GridToolbarExport
            csvOptions={{
              fileName: "Woechentliche Stunden",
              delimiter: ";",
              utf8WithBom: true,
            }}
          />
          <GridToolbarQuickFilter />
          <Button
            color="primary"
            startIcon={<CachedIcon />}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </GridToolbarContainer>
      </Box>
    );
  }

  return (
    <>
      <br />
      <Box
        display="flex"
        flexDirection="row"
        overflow="auto"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="headline"
            component="h3"
            align="left"
            paddingLeft={"10px"}
            color={blue[500]}
          >
            Wöchentliche Stunden
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ marginRight: 2, marginTop: 5 }}
        ></Box>
      </Box>
      <br />
      <Typography
        variant="caption"
        component="h6"
        align="left"
        color={red.A400}
      >
        {error}
      </Typography>

      <Box>
        <div style={{ height: 680, width: "90%" }}>
          <DataGrid
            // apiRef={apiRef}
            columns={columnsBuilderWeeklyHours()}
            disableRowSelectionOnClick
            initialState={{
              ...approvedParentsData.initialState,
              columns: {
                ...approvedParentsData.initialState?.columns,
                columnVisibilityModel: { userId: false },
              },
              filter: {
                filterModel: {
                  items: [
                    { field: "fullName", operator: "contains", value: "" },
                  ],
                },
              },
            }}
            slots={{ toolbar: DefinedToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            density="compact"
            rows={approvedParentsData}
            getRowId={(row) => row.userId}
          />
        </div>
      </Box>
    </>
  );
}

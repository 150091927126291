import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography, Paper, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import UploadIcon from '@mui/icons-material/Upload';
import { LinearProgress } from '@mui/material';
import { getBaseUrl, getStorageItem, getString, getValue, readFileAsBase64 } from '../../../../Common/Utility';
import { Delete, Get, Post, Put } from '../../../../Common/WebRequest';
import SuccessMessage from './SuccessMessage';
import ErrorMessage from './ErrorMessage';
import { blue } from '@mui/material/colors';
import { apiUrl } from '../../../../config';
import DialogBoxWithYesNo from '../../../../components/Dialog/DialogBoxWithYesNo';
import { useNavigate } from 'react-router-dom';

const monthsInGerman = [
  'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
];

const fixedYears = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

const NewPayment = ({ state,paymentId,reload,onDelete }) => {
  const navigate = useNavigate();
  const Max_Size_5_MB = 5 * 1024 * 1024; // 5 MB
  const [uploadProgress, setUploadProgress] = useState(0);
  const [userId, setUserId] = useState(0);
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const[errorOpen,setErrorOpen]= useState(false);
  const[success,setSuccess]= useState('')
  const[readyToSubmit,setReadyToSubmit]=useState(true); 
  const[openDialog,setOpenDialog]= useState(false);
  const[confTitle,setConfTitle]= useState('')
  const[confText,setConfText]= useState('')
  const[operation,setOperation]=useState('')
  const[isUploaded,setIsUploaded]=useState(false);
  const[isIbanError,setIsIbanError]= useState("0");
  const [uploading, setUploading] = useState(false);
  
  
  // Separate state variables for upload errors and file size errors
  const [uploadErrorAbrechnung, setUploadErrorAbrechnung] = useState(false);
  const [fileSizeErrorAbrechnung, setFileSizeErrorAbrechnung] = useState(false);
  
  const [uploadErrorStundennachweise, setUploadErrorStundennachweise] = useState(false);
  const [fileSizeErrorStundennachweise, setFileSizeErrorStundennachweise] = useState(false);

  const [uploadErrorStundennachweiseNachreichen, setUploadErrorStundennachweiseNachreichen] = useState(false);
  const [fileSizeErrorStundennachweiseNachreichen, setFileSizeErrorStundennachweiseNachreichen] = useState(false);

  const [reloadData,setReloadData]= useState(false);
  const [isDeleted,setIsDeleted]= useState(false);
  const [formData, setFormData] = useState({
    month: '',
    monthLabel: '',
    year: '',
    pdfAbrechnung: null,
    pdfStundennachweise: null,
    pdfStundennachweiseNachreichen:null,
    gesamtStunden: '',
    gesamtEuro: '',
    bemerkung: ''
  });

  useEffect(() => {
    (async () => {
      console.log("paymentId: " + paymentId);
      console.log("State: " + state);
      handleResetClick();
      let userIdValue = await getStorageItem("login", "userId");
      if (userIdValue <= 0) {
        return; // log in page
      }
      setUserId(userIdValue)
    })();
     
  }, [userId,state,paymentId,reload]);

  useEffect(() => {
    (async () => {
      
      if (paymentId > 0) {
        try {
          setReadyToSubmit(false);
          const res = await Get("Payment", paymentId);
          if (res.isSuccess) {
            fillPaymentData(res.result);                      

          }
        } catch (error) {
          console.error("Error fetching payment data:", error);
          setError("Fehler beim Abrufen der Zahlungsdaten. Bitte versuche es erneut.");
        }
        finally
        {
          setReadyToSubmit(true)
        }
      }
    })();
  }, [paymentId,reloadData]);

  useEffect(() => {
    if(state>0)
          disableAllChildren(true);
        else 
          disableAllChildren(false);

  }, [state]);

  useEffect(() => {
    if (uploading) {
      setWarning("Upload läuft...");
    } else if (!uploading || uploadProgress === 100) {
      setWarning("");  
    }
  }, [uploading, uploadProgress]);
  
  const fillPaymentData =   (paymentData) => {    
    const { yearMonth, totalHours, totalAmount, calculationsFile, timesheetsFile,timesheetsFileLater, comments} = paymentData;
    const [year, month] = yearMonth.split('-');
    setFormData({
      ...formData,
      month: month,
      monthLabel: monthsInGerman[parseInt(month) - 1], // Convert month to German label
      year: year,
      yearMonth: yearMonth,
      gesamtStunden: totalHours,
      gesamtEuro: totalAmount,
      pdfAbrechnung: calculationsFile ? {name: calculationsFile} : null,
      pdfStundennachweise: timesheetsFile ? {name: timesheetsFile} : null,
      pdfStundennachweiseNachreichen: timesheetsFileLater ? {name: timesheetsFileLater} : null,
      bemerkung: comments
    });
  };
       
  const handleChange = (field, value) => {
    if (field === 'month') {
      const monthIndex = parseInt(value) - 1;
      setFormData({
        ...formData,
        [field]: value,
        [`${field}Label`]: monthsInGerman[monthIndex]
      });
    } else {
      setFormData({
        ...formData,
        [field]: value
      });
    }
  };

  
  const handleFileChange = async (file, fileType) => {
    setUploading(false);
    setUploading(true);
    try {
        //const file = e.target.files[0];
        if (!file) {
          
            return; // No file selected, do nothing
        }  
        
        if (file && !file.type.includes('pdf')) 
        {     
            console.log('Please select a PDF file.');
            setError("Nur PDF-Dateien erlaubt!")
            return;
          }
        if (file.size > Max_Size_5_MB) {
            throw new Error('Dateigröße überschreitet das Limit von 5 MB.');
        }

        setUploadProgress(0);

        const timeout = 10;
        const duration = 1000; // Simulating 5 seconds for upload
        const increment = 100 / (duration / timeout);

        const interval = setInterval(() => {
            setUploadProgress(prevProgress => Math.min(prevProgress + increment, 100));
        }, timeout);

        await new Promise(resolve => setTimeout(resolve, duration));

        clearInterval(interval);
        setUploadProgress(0);

        setFormData({
            ...formData,
            [fileType]: file
        });

        // Reset error states for the corresponding file type
        fileType === 'pdfAbrechnung' ? setFileSizeErrorAbrechnung(false) : setFileSizeErrorStundennachweise(false);
        fileType === 'pdfAbrechnung' ? setUploadErrorAbrechnung(false) : setUploadErrorStundennachweise(false);
        
    } catch (error) {
        console.error('File upload failed:', error);
        fileType === 'pdfAbrechnung' ? setFileSizeErrorAbrechnung(true) : setFileSizeErrorStundennachweise(true);
        fileType === 'pdfAbrechnung' ? setUploadErrorAbrechnung(true) : setUploadErrorStundennachweise(true);
    }
    finally{
      setUploading(false);
    }
}; 



const handleFileInputChange = (event, fileType) => {
  const file = event.target.files[0];
  
  setError("")
  handleFileChange(file, fileType);
  setIsUploaded(true);
  setWarning("");

};
 
  const handleFileRemove = (fileType, e) => {
    console.log('File remove:', fileType);
    e.stopPropagation();
    
    const uniqueKey = Date.now();  
    setFormData(prevFormData => ({
      ...prevFormData,
      [fileType]: uniqueKey,
     
      [`fileInputKey_${fileType}`]: uniqueKey,
    }));
  };

  const handleResetClick = () => {
    setError("")
    setSuccess("")
    setWarning("")
    setIsIbanError(false);
    setIsUploaded(false);
    setIsDeleted(false)
    setFileSizeErrorAbrechnung(false)
    setFileSizeErrorStundennachweise(false)
    setFileSizeErrorStundennachweiseNachreichen(false)
    
    setUploadErrorAbrechnung(false)
    setUploadErrorStundennachweise(false)
    setUploadErrorStundennachweiseNachreichen(false)         
     setFormData({
      month: '',
      monthLabel: '',
      year: '',
      pdfAbrechnung: null,
      pdfStundennachweise: null,
      pdfStundennachweiseNachreichen:null,
      gesamtStunden: '',
      gesamtEuro: '',
      bemerkung: ''
    }); 
    handleFileRemove('pdfAbrechnung',createMockEvent());
    handleFileRemove('pdfStundennachweise',createMockEvent());
    handleFileRemove('pdfStundennachweiseNachreichen',createMockEvent()); 
  };

  const createMockEvent = () => {
    return {
      stopPropagation: () => {}  
    };
  };
  const handleDeleteClick =  ()=>{
    setConfTitle('Bestätigung löschen');
    setConfText('Möchten Sie diese Zahlung wirklich löschen?')
    setOperation('Löschen');
    setOpenDialog(true);
  }
  const handleCloseDialog =() =>
  {
    setOpenDialog(false);
  }
  const handleYesClick = async()=>
  {
    // DoDeleteApi
    var res = await Delete("payment/delete-payment-for-user/"+userId, paymentId);
    if(res.isSuccess)
    {
      setIsDeleted(true);
      console.log("State:"+state);
      setSuccess("Die Zahlung wurde gelöscht!");
      setOpenDialog(false);      
      onDelete();      
    }
    else 
    {
      setError(res.Error);
    }
  }
  const validateBeforeSubmit = () => { 
    
    const currentDate = new Date();
    const selectedDate = new Date(`${formData.year}-${formData.month}-01`);
  
    // Check if selected month & year are in the future
    if (selectedDate > currentDate) {
      setConfTitle("Zukunftsdatum bestätigen");
      setConfText(
        "Das ausgewählte Datum liegt in der Zukunft. Sind Sie sicher, dass Sie fortfahren möchten?"
      );
      setOperation("Fortsetzen");
      setOpenDialog(true);
      return false; // Pause validation until user confirms
    }
    // Validate month
    if (!formData.month) {
      setError("Bitte wählen Sie einen Monat aus.");
      return false;
    }
  
    // Validate year
    if (!formData.year) {
      setError("Bitte wählen Sie ein Jahr aus.");
      return false;
    }  
       // Validate pdfAbrechnung
       if ( formData.pdfAbrechnung==='' || getString( formData.pdfAbrechnung.name) ==='') {
        setError("Bitte laden Sie die Abrechnung im PDF-Format hoch.");
        return false;
      }
    
      // Validate pdfStundennachweise
      if ( formData.pdfStundennachweise==='' || getString(formData.pdfStundennachweise.name)==='') {
        setError("Bitte laden Sie die Stundennachweise im PDF-Format hoch.");
        return false;
      }
      // Validate pdfStundennachweiseNachreichen
      if ( state ===1 && ( formData.pdfStundennachweiseNachreichen==='' || getString(formData.pdfStundennachweiseNachreichen.name)==='')) {
        setError("Bitte laden Sie die StundennachweiseNachreichen im PDF-Format hoch.");
        return false;
      }
    // Validate Gesamt Stunden
    if ( getValue( formData.gesamtStunden)===0) {
      setError("Bitte geben Sie die Gesamtstunden ein.");
      return false;
    }
  
    // Validate Gesamt €
    if ( getValue(formData.gesamtEuro)===0) {
      setError("Bitte geben Sie den Gesamtbetrag in Euro ein.");
      return false;
    }
     
    return true;
  };
  
  const handleSubmitClick = async () => {
    try {
      setError("")
      setSuccess("")
      setWarning("")
      setIsDeleted(false);
      setIsIbanError(false);
    if(state>1)
    {
      setError("Der Vorgang ist nicht möglich!")
      return;
    }
    if(!validateBeforeSubmit())
    {
      setErrorOpen(true);
      return;
    }
     
    // Extract file names
    const calculationsFileName = formData.pdfAbrechnung ? formData.pdfAbrechnung.name : '';
    const timesheetsFileName = formData.pdfStundennachweise ? formData.pdfStundennachweise.name : '';
    const timesheetsFileLaterName = formData.pdfStundennachweiseNachreichen ? formData.pdfStundennachweiseNachreichen.name : '';
   /*var pdfAbrechnungBlob =await readFileAsBase64(formData.pdfAbrechnung);
   var pdfStundennachweiseBlob = await readFileAsBase64(formData.pdfStundennachweise)
    var pdfStundennachweiseNachreichenBlob = await readFileAsBase64(formData.pdfStundennachweiseNachreichen)
*/
const pdfAbrechnungBlob =  await readFileAsBase64(formData.pdfAbrechnung) ;
const pdfStundennachweiseBlob =  await readFileAsBase64(formData.pdfStundennachweise)  ;
const pdfStundennachweiseNachreichenBlob =   await readFileAsBase64(formData.pdfStundennachweiseNachreichen)  ;


    const formDataToSend = {
      paymentId: paymentId,
      userId: userId,
      yearMonth: `${formData.year}-${formData.month}`,
      totalHours: getValue( formData.gesamtStunden),
      totalAmount:  getValue( formData.gesamtEuro),
      calculationsFile: calculationsFileName,
      timesheetsFile: timesheetsFileName,
      timesheetsFileLater: timesheetsFileLaterName,
      comments: formData.bemerkung,
      paymentState: state===1? 1:0,
      CalculationsFileData: pdfAbrechnungBlob ,
      TimesheetsFileData:  pdfStundennachweiseBlob ,
      TimesheetsFileLaterData: pdfStundennachweiseNachreichenBlob
    };    
    
      setReadyToSubmit(false);
      var res;
      if(paymentId ===0)
      res = await Post("Payment", formDataToSend  );
      else if(paymentId>0 && state<=0)
      res = await Put("Payment",paymentId, formDataToSend);
      else if(paymentId>0 && state===1)
      {
        res = await Put("Payment/Upload-TimesheetsFileLater-Only",paymentId, formDataToSend);
        
      }
      else 
      return;
   
    if(res.isSuccess)
      {
        var amountTotal=res.result?.totalAmount
        if(paymentId===0)  
        setSuccess("Neue Zahlung wurde übermittelt! \r\n Gesamt: "+amountTotal + " €")
      else if(paymentId>0 && state===0)
      {
        setSuccess("Zahlung wurde aktualisiert! \r\n Gesamt: "+amountTotal + " €")
        // if state is 1 then it is uploaded
        if(state===1)
        setIsUploaded(false);        
      }
      else if(paymentId>0 && state===1)
      {
        setSuccess("Die Datei wurde hochgeladen!")
        setIsUploaded(false);
      }
    }
      else if (!res.isSuccess) {
        setError(res.Error);
        setErrorOpen(true);
        console.error("Error:- " + res.Error);
        if(res.Error.toLowerCase().includes("profil"))
        {          
          setIsIbanError(true);
        }
      } else {
        setError("");  
      }
     
    } catch (error) {
      setErrorOpen(true);
      if (error instanceof ProgressEvent) {
        console.error("Network error:", error);
        setError("#Netzwerkfehler beim Hochladen der Datei. Bitte nochmal versuchen.\r\n"+error);
      } else {
      setError("Fehler beim Absenden des Formulars.\r\n Bitte nochmal versuchen.\r\n"+ error);
      console.error("Error:- " + error);
      }
    }

    finally{
      setReadyToSubmit(true);
    }
  };
   
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, fileType) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file, fileType);
  };
  
  const handleSuccessClose = () => {    
    if(paymentId===0 || isDeleted)
    { 
    handleResetClick();
    
    }
  else{
    setReloadData(!reloadData);
    setSuccess("")
  }
  };
  const handleErrorClose = () => {
     setErrorOpen(false)
     if(isIbanError)
     navigate("/userprofile?isIbanError=1");
   // handleResetClick();
  };
  const openFileWindow = async (fileName) => {
    var res =await Get ("Payment/"+paymentId+"/file",fileName);
    if(res?.isSuccess)
    {                      
        var fullPdfpath = getBaseUrl(apiUrl) + "/" + res.result;
        window.open(fullPdfpath, '_blank');     
    }
    else
    {
      window.open(`file-not-found`, '_blank');
    }
  };
     
  const renderFileUpload = (fileType, label, readOnly) => {
    const fileName = formData[fileType] ? formData[fileType].name : '';  
    if (fileName === "" && readOnly) {
      return null; // Return null to render nothing when conditions are met
    }  
    return (
      <>  
         
        <div
          style={{
            border: '2px dashed #aaa',
            borderRadius: '8px',
            padding: '5px',
            paddingLeft:'15px',
            textAlign: 'left',
            marginBottom: '5px',
            fontWeight: 'bolder',
            // backgroundColor: fileName ? '#E6FFE6' : '#F5F6F5',
            backgroundColor: fileName ? '#E6FFE6' : '#F5F6F5',
          }}
          onDragOver={!readOnly ? (e) => handleDragOver(e) : undefined}
          onDrop={!readOnly ? (e) => handleDrop(e, fileType) : undefined}
        >         
      
           
        {(  state<=0 || (!readOnly && !fileName?.includes("StundennachweiseNachreichen"))) && (
          <>
          <Typography align='left' component="span" variant="body2" color="primary" sx={{ textDecoration: '' }}>
          {label} (PDF)  (5 MB max.) *
        </Typography>
          <Typography align='center' component="" variant="body1" sx={{ justifyContent: "center", mt: 1, color: '#000033' }}>
            Drag & Drop your file or {' '}
            <label htmlFor={`upload-${fileType}`} style={{ textDecoration: 'underline', color: 'darkblue', cursor: 'pointer' }}>
              Browse
            </label>
          </Typography>
          </>
        )}        
            <input
            type="file"
            accept=".pdf"            
             key={`fileInput_${fileType}_${formData[`fileInputKey_${fileType}`]}`} // Use the unique key here
           // onClick={e => e.target.value =''}
            id={`upload-${fileType}`}
            
            style={{ display: 'none' }}
            onChange={(e) => handleFileInputChange(e, fileType)} // Pass fileType here
            
          />   
          {fileName && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '2px' }}>
                        
            <Typography
                  component="p"
                  variant="body1"
                  sx={{ 
                    flex: 1, 
                    color: 'green', 
                    marginRight: '8px', 
                    cursor: state !== -1 ? 'pointer' : 'default',
                    '&:hover': {
                      textDecoration: state !== -1 ? 'underline' : 'none',
                    }
                  }}
                  onClick={state !== -1 ? () => openFileWindow(fileName) : undefined}
            >
              {fileName}
            </Typography>           
              {state <= 0 && (
              <IconButton size="small" onClick={(e) => handleFileRemove(fileType, e)}>
                <ClearIcon color="info" />
              </IconButton>
            )}
            </div>
          )}
        </div>
        {fileType === 'pdfAbrechnung' && uploadErrorAbrechnung && (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            Datei-Upload fehlgeschlagen. Bitte versuchen Sie es erneut.
          </Typography>
        )}
        {fileType === 'pdfStundennachweise' && uploadErrorStundennachweise && (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            Datei-Upload fehlgeschlagen. Bitte versuchen Sie es erneut.
          </Typography>
        )}

          {fileType === 'pdfStundennachweiseNachreichen' && uploadErrorStundennachweiseNachreichen && (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            Datei-Upload fehlgeschlagen. Bitte versuchen Sie es erneut.
          </Typography>
        )}  

        {fileType === 'pdfAbrechnung' && fileSizeErrorAbrechnung && (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            Dateigröße überschreitet das Limit von 5 MB.
          </Typography>
        )}
        {fileType === 'pdfStundennachweise' && fileSizeErrorStundennachweise && (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            Dateigröße überschreitet das Limit von 5 MB.
          </Typography>
        )}
        {fileType === 'pdfStundennachweiseNachreichen' && fileSizeErrorStundennachweiseNachreichen && (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            Dateigröße überschreitet das Limit von 5 MB.
          </Typography>
        )}
      </>
    );
  };
  
  function disableAllChildren(disable) {
    const div = document.getElementById('parentDiv');
    const children = div.querySelectorAll('*');

    children.forEach(child => {
      child.disabled = disable;
    });
  }
  return (
    <>
        
        {warning && (
          <Typography variant="body2" sx={{ color: 'blue', backgroundColor: '#fdf7c3', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
            <UploadIcon sx={{ marginRight: '8px' }} />
            {warning}
          </Typography>
        )}

        {error && (
          <Typography variant="body2" sx={{ color: 'red', backgroundColor: '#ffebee', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
            <ErrorIcon sx={{ marginRight: '8px' }} />
            {error}
          </Typography>
        )}

        {error && (
                <ErrorMessage 
                open={errorOpen}
                onClose={handleErrorClose}
                title="Fehler!"
                body={error}
              />
              )}

          {success && (
            <Typography variant="body2" sx={{ color: 'green', backgroundColor: '#e8f5e9', padding: '8px', borderRadius: '4px', marginBottom: '16px' }}>
              <CheckCircleIcon sx={{ marginRight: '8px' }} />
              {success}
            </Typography>
          )}
          {success && (
                  <SuccessMessage
                  open={success.length>0}
                  onClose={handleSuccessClose}
                  title="Erfolg!"
                  body={success}
                />
                )}
      <div id="parentDiv">
      <Grid container spacing={1} justifyContent="left" marginTop={1}  >
        <Grid item xs={12} md={6}>
          <Paper elevation={2} style={{ padding: '20px' }}  >
            
          <div style={{ position: 'relative' }}>
              <Typography  
                variant="headline"
                component="h3"
                align="left"
                paddingLeft={"10px"}
                color={blue[500]}
              >
                {state === -1 ? "Neue Zahlung" : "Zahlung prüfen"}
              </Typography>                                         
                {formData.month && state === 0 && (
                  <label style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop:'5px',
                    marginRight: '10px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    color: 'white',
                    background: '#ffaf8f',
                    borderRadius: '10px',
                  }}>Wartend</label>
                )}
                {formData.month && state === 1 && (
                  <label style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop:'5px',
                    marginRight: '10px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    color: 'blue',
                    background: '#ffd966',
                    borderRadius: '10px',
                  }}>Genehmigt</label>
                )}
                {formData.month && state === 2 && (
                  <label style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,                    
                    marginRight: '10px',                    
                    marginTop:'5px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                  
                    color: 'white',
                    background: '#4CAF50',
                    borderRadius: '10px',
                  }}>Bezahlt</label>
                )}            
          </div>  
           
            <br/>
            <Grid container spacing={2}  >
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="month-label">Monat *</InputLabel>
                  <Select disabled={state>0}
                    labelId="month-label"
                    id="month"
                    value={formData.month}
                    label="Monat *"                   
                    onChange={(e) => handleChange('month', e.target.value)}
                  >
                    {monthsInGerman.map((month, index) => (
                      <MenuItem key={index} value={(index + 1).toString().padStart(2, '0')}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="year-label">Jahr *</InputLabel>
                  <Select disabled={state>0}
                    labelId="year-label"
                    id="year"
                    value={formData.year}
                    label="Jahr *"
                    onChange={(e) => handleChange('year', e.target.value)}
                  >
                    {fixedYears.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography align='left' component="body" variant="body1" color="primary" sx={{ marginLeft:"5px" }} >
            Files
          </Typography>
            {  renderFileUpload('pdfAbrechnung', 'Abrechnung',state >0 )}
            { renderFileUpload('pdfStundennachweise', 'Stundennachweise',state >0)}
            {/* {renderFileUpload('pdfStundennachweiseNachreichen', 'StundennachweiseNachreichen')} */}
            {  state>=1 &&  renderFileUpload('pdfStundennachweiseNachreichen', 'StundennachweiseNachreichen', state>1    )}

            {uploadProgress > 0 && uploadProgress < 100 && (
                <LinearProgress variant="determinate" value={uploadProgress} />
              )}
            <br/>
            <TextField
              fullWidth
              label="Gesamt Stunden *"
              variant="outlined"
              value={formData.gesamtStunden}
              onChange={(e) => handleChange('gesamtStunden', e.target.value.replace(/[^0-9]/g, ''))}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Gesamt € *"
              variant="outlined"
              value={formData.gesamtEuro}
              onChange={(e) => handleChange('gesamtEuro', e.target.value.replace(/[^0-9]/g, ''))}
              sx={{ mb: 2 }}
            />
            <TextField
              multiline
              rows={5}
              label="Bemerkung"
              value={formData.bemerkung}
              onChange={(e) => handleChange('bemerkung', e.target.value)}
              style={{ width: '100%', marginBottom: '16px' }}
            />
            {paymentId === 0 ? (
                  <>
                    <Button variant="outlined" color="primary" disabled={!readyToSubmit} endIcon={<DoneIcon />} onClick={handleSubmitClick} fullWidth sx={{ mr: 5, width: '140px', textTransform: 'capitalize' }}>
                      Abschicken
                    </Button>
                    <Button variant="outlined" color="primary" onClick={handleResetClick} fullWidth sx={{ width: '140px', textTransform: 'capitalize' }}>
                      Zurücksetzen
                    </Button>
                  </>
                ) : state === 0 ? (
                  <>
                    <Button variant="outlined" disabled={!readyToSubmit} color="primary" endIcon={<DoneIcon />} onClick={handleSubmitClick} fullWidth sx={{ width: '130px', textTransform: 'capitalize' }}>
                      Aktualisieren 
                    </Button>
                    <Button variant="outlined" disabled={!readyToSubmit}   color ="error"  onClick={handleDeleteClick} fullWidth sx={{ width: '130px',ml:'10px', textTransform: 'capitalize' }}>
                      Löschen 
                    </Button>
                  </>
                ): state ===1 &&  isUploaded ?(
                  <>
                   <Button variant="outlined" disabled={!readyToSubmit} color="primary" endIcon={<DoneIcon />} onClick={handleSubmitClick} fullWidth sx={{ width: '130px', textTransform: 'capitalize' }}>
                   Einreichen 
                    </Button>
                  </>
                ) : null}

          </Paper>
        </Grid>
      </Grid>
                <DialogBoxWithYesNo
                  open={openDialog}
                  onClose={handleCloseDialog}
                  onYes={handleYesClick}
                  title={confTitle}
                  text={confText}
                  operationText={operation}
                />
      </div>
    </>
  );  
};

export default NewPayment;

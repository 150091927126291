const columnsBuilderWeeklyHours = (props) => {
  const columns = [
    { field: "userId", headerName: "ID", width: 70 },
    { field: "fullName", headerName: "Name", flex: 1 },
    { field: "email", headerName: "E-Mail", flex: 1 },
    { field: "yearMonth", headerName: "Monat", width: 100 },
    { field: "workHourPerWeek", headerName: "Wöch. Kap.", flex: 2 },
  ];

  return columns;
};

export default columnsBuilderWeeklyHours;
